<template>
  <el-card class="px-16 sm:px-24 py-16">
    <h1 class="font-medium text-lg mb-20">Update password</h1>

    <div class="flex flex-col items-start sm:flex-row sm:items-end mb-20">
      <ValidationObserver ref="updatePassword" tag="div" class="flex-1 w-full">
        <el-input
          v-model="password"
          type="password"
          input-label="Enter the password below"
          input-class="rounded"
          input-name="password"
          placeholder="Enter the password"
          rules="required|validate_pass"
          class="sm:mr-16 sm:w-auto mb-10 sm:mb-0"
        />
      </ValidationObserver>
      <el-button :loading="loading" @click="onGeneratePassword">Generate password</el-button>
    </div>

    <el-card class="px-16 sm:px-24 py-16 bg-grey-100 border border-grey-200 shadow-none">
      <h4 class="text-base text-grey-500 font-medium leading-big mb-4">Password requirements</h4>

      <ul class="list-disc pl-16 text-grey-300">
        <li>Password must contain a minimum of 8 characters</li>
        <li>Password must contain at least one lower case characters</li>
        <li>Password must contain at least one upper case characters</li>
        <li>Password must contain at least one number</li>
        <li>Password must contain at least one symbol</li>
      </ul>
    </el-card>

    <div class="flex flex-col sm:flex-row sm:items-center mt-20">
      <div class="mb-10 sm:mb-0">
        <p class="font-semibold mb-8">The system can send a mail containing the password and login link</p>
        <el-checkbox v-model="isSendMail" :disabled="loading">Send mail</el-checkbox>
      </div>

      <el-button type="primary" :loading="loading" class="ml-auto" @click="onUpdatePassword">Update</el-button>
    </div>
  </el-card>
</template>

<script>
import { generatePassword } from '@/core/helper-functions'
import { mapActions } from 'vuex'

const ElCard = () => import('@/components/shared/ELCard')
const ElInput = () => import('@/components/shared/ELInput')
const ElButton = () => import('@/components/shared/ELButton')
const ElCheckbox = () => import('@/components/shared/ELCheckbox')

export default {
  name: 'UserEditProfile',

  components: { ElCard, ElInput, ElButton, ElCheckbox },

  props: {
    loading: { type: Boolean, default: false }
  },

  data () {
    return {
      password: '',
      isSendMail: false
    }
  },

  methods: {
    ...mapActions(['showNotification']),

    onGeneratePassword () {
      this.password = generatePassword()
    },

    onUpdatePassword () {
      this.$refs.updatePassword.validate().then(isValid => {
        if (!isValid) {
          const errors = []

          // generate array of arrows
          Object.keys(this.$refs.updatePassword.errors).map(error => {
            errors.push(...this.$refs.updatePassword.errors[error])
          })

          this.showNotification({ type: 'error', message: errors })
          return false
        }

        this.$emit('update-password', { newPassword: this.password, isNotify: this.isSendMail })
      })
    }
  }
}
</script>
